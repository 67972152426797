import React from 'react'
import { connect } from 'react-redux'

import CoreLayout from 'react-material-site/lib/layouts/CoreLayout'
import Content from 'react-material-site/lib/components/Content'
import Button from 'react-material-site/lib/components/Button'

import './Home.scss'

export const Home = (props) => (
  <CoreLayout {...props}>
    <div className='co-home__hero'>
      <Content
        style={{maxWidth: '450px'}}
        headerStyle={{padding: '5px 0'}}
        headerType='headline6'
        header={props.content.text.get(['home', 'blurb', props.ui.language])}
        bodyStyle={{margin: '10px'}}
        bodyTag='span'
        body={
          <div>
            {!props.auth.isAuthenticated() && <Button raised to='/login' text='Login' />}
            {props.auth.isAuthenticated() && <Button raised to='/organizations' text='Organizations' />}
          </div>
        }
      />
    </div>
  </CoreLayout>
)

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
  auth: state.auth,
  ui: state.ui,
  content: state.content
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
