import React, { Component } from 'react';
import { connect } from 'react-redux';

import CoreLayout from 'react-material-site/lib/layouts/CoreLayout';
import {
  sortList,
  filterList,
  goToPage,
} from 'react-material-site/lib/modules/router';
import { getLabel } from 'react-material-site/lib/utils/content';
import { TabBar, Tab } from 'react-material-site/lib/components/Tabs';
import Content from 'react-material-site/lib/components/Content';
import Button from 'react-material-site/lib/components/Button';
import Fab from 'react-material-site/lib/components/Fab';
import Table from 'react-material-site/lib/components/Table';
import Pager from 'react-material-site/lib/components/Pager';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from 'react-material-site/lib/components/Dialog';
import Form from '../../components/Form';
import ImportExport from '../../components/ImportExport';
import Item from '../../forms/Item';

import {
  fetchDocumentList,
  fetchDocumentItem,
  deleteDocumentItem,
  documentFormChange,
  documentFormSubmit,
} from '../../modules/document';
import {
  fetchOrganizationItem,
  organizationFormChange,
  organizationFormSubmit,
} from '../../modules/organization';
import { getEmbedCode } from '../../utils/embed';

import './OrganizationItem.scss';

const tabsToNames = {
  0: 'create',
  1: 'items',
  2: 'customize',
  3: 'integration',
};

const namesToTabs = {
  create: 0,
  items: 1,
  customize: 2,
  integration: 3,
};

class OrganizationItem extends Component {
  state = {
    activeTab: 0,
    delete: false,
  };

  componentWillMount() {
    this.props.fetchOrganizationItem(this.props.match.params);
    this.props.fetchDocumentList(this.props.match.params);
    if (this.props.match.params._item_id) {
      this.props.fetchDocumentItem(this.props.match.params);
    }
    this.setState({ activeTab: namesToTabs[this.props.match.params.tab] });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params._id !== nextProps.match.params._id) {
      this.props.fetchOrganizationItem(nextProps.match.params);
      this.props.fetchDocumentList(nextProps.match.params);
    }

    if (this.props.match.params.tab !== nextProps.match.params.tab) {
      this.setState({ activeTab: namesToTabs[nextProps.match.params.tab] });
      if (nextProps.match.params.tab === 'items') {
        if (this.props.match.params._item_id) {
          this.props.fetchDocumentItem(nextProps.match.params);
        } else {
          this.props.fetchDocumentList(nextProps.match.params);
        }
      }
    }

    if (
      this.props.router.location.search !== nextProps.router.location.search
    ) {
      this.props.fetchDocumentList(this.props.match.params);
    }
  }

  render() {
    const props = this.props;
    const state = this.state;
    const _item_id = props.match.params._item_id;
    const activeTab = this.state.activeTab;
    const search = new URLSearchParams(props.router.location.search);

    if (Object.keys(props.organization.item).length === 0) {
      return <div></div>;
    }

    return (
      <CoreLayout {...props}>
        <div style={{ textAlign: 'center', margin: 'auto', maxWidth: '500px' }}>
          <Content
            style={{ maxWidth: '450px' }}
            headerStyle={{ padding: '35px' }}
            headerType="headline6"
            header={
              <div>
                {props.organization.item.name}
                <a
                  href={`${process.env.EMBED_URL}?organization=${props.organization.item._id}`}
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                  rel="noopener noreferrer"
                >
                  <Button
                    raised
                    text="Preview"
                    icon="open_in_new"
                    style={{ marginLeft: '25px' }}
                  />
                </a>
              </div>
            }
            bodyStyle={{ margin: '10px' }}
            bodyTag="span"
          />
        </div>

        <TabBar
          activeTabIndex={this.state.activeTab}
          onActivate={(evt) => {
            const tab = tabsToNames[evt.detail.index];
            props.goToPage(
              `/organizations/${props.organization.item._id}/${tab}`
            );
          }}
        >
          <Tab icon="add" label="Create" />
          <Tab icon="list" label="Items" />
          <Tab icon="settings_applications" label="Customize" />
          <Tab icon="code" label="Integration" />
        </TabBar>

        <div style={{ minHeight: '900px' }}>
          {tabsToNames[activeTab] === 'create' && (
            <div
              style={{
                textAlign: 'center',
                margin: 'auto',
                maxWidth: '1000px',
              }}
            >
              <Item
                onChange={props.documentFormChange}
                onSubmit={props.documentFormSubmit}
                data={props.document.form}
                languages={props.ui.languages}
                actionLabel="Create"
              />
            </div>
          )}

          {tabsToNames[activeTab] === 'items' && _item_id && (
            <div
              style={{
                textAlign: 'center',
                margin: '50px auto',
                maxWidth: '500px',
                position: 'relative',
              }}
            >
              <Button
                raised
                text="Back to List"
                icon="keyboard_arrow_left"
                style={{ left: 0, position: 'absolute' }}
                to={`/organizations/${props.organization.item._id}/items`}
              />

              <Button
                text="Delete Item"
                icon="delete_forever"
                style={{ right: 0, position: 'absolute' }}
                onClick={() => {
                  this.setState({ delete: true });
                }}
              />

              <Item
                id={_item_id}
                onChange={props.documentFormChange}
                onSubmit={props.documentFormSubmit}
                data={Object.assign(
                  {},
                  props.document.item,
                  props.document.form
                )}
                languages={props.ui.languages}
                actionLabel="Update"
              />
            </div>
          )}

          {tabsToNames[activeTab] === 'items' && !_item_id && (
            <div
              style={{
                textAlign: 'center',
                margin: '20px 10px',
                width: '100%',
                minWidth: '550px',
                minHeight: '900px',
                position: 'relative',
              }}
            >
              <Fab
                icon="add"
                label="New"
                onClick={() => {
                  props.goToPage(
                    `/organizations/${props.organization.item._id}/create`
                  );
                }}
                style={{ left: '-150px', top: '35px', zIndex: '2' }}
              />

              <Pager {...props.document.pager} />
              <Table
                wrap
                fields={{
                  // TODO fix multiple language support for filtering
                  name: {
                    label: 'Name',
                    style: { width: '30%' },
                    filter: true,
                    render: (field) => {
                      return getLabel(field, props.ui.language);
                    },
                  },
                  type: {
                    label: 'Type',
                    style: { width: '10%' },
                    filter: true,
                  },
                  author: {
                    label: 'Author',
                    style: { width: '15%' },
                    filter: true,
                  },
                  year: { label: 'Year', style: { width: '5%' } },
                  published: {
                    label: 'Published',
                    style: { width: '5%' },
                    render: (value) => value.toString(),
                  },
                }}
                filterChange={props.filterList}
                search={search}
                rowClick={(row) => {
                  props.goToPage(
                    `${props.router.location.pathname}/${row['_id']}`
                  );
                }}
                sortChange={props.sortList}
                sortField={search.get('sortField')}
                sortDir={search.get('sortDir')}
                data={props.document.list}
              />

              <Pager
                {...props.document.pager}
                parseUrl={(url) =>
                  url ? url.replace(process.env.API_URL, '') : ''
                }
              />
              {console.log(props.match.params._id)}
              <ImportExport id={props.match.params._id} />
            </div>
          )}

          {tabsToNames[activeTab] === 'customize' && (
            <div
              style={{
                textAlign: 'center',
                margin: 'auto',
                maxWidth: '1000px',
              }}
            >
              <Form
                id={props.organization.item._id}
                style={{ background: 'none' }}
                onChange={props.organizationFormChange}
                onSubmit={props.organizationFormSubmit}
                data={Object.assign(
                  {},
                  props.organization.item,
                  props.organization.form
                )}
                submitDual
                fields={{
                  published: { type: 'switch', label: 'Published' },
                  name: { type: 'text', label: 'Name', required: true },
                  custom_sort: {
                    type: 'select',
                    label: 'Sort Items by',
                    options: [
                      'date:ascending',
                      'date:descending',
                      'year:ascending',
                      'year:descending',
                    ],
                  },
                  date_format: {
                    type: 'select',
                    label: 'Date display format',
                    options: ['EEE, MMM d', 'MMM d, yyyy'],
                  },
                  // primary_color: { type: 'text', label: 'Primary Color' },
                  // secondary_color: { type: 'text', label: 'Secondary Color' },
                  // font: { type: 'text', label: 'Font' },
                  custom_cta_label: { type: 'text', label: 'CTA Label' },
                  custom_cta_icon: {
                    type: 'select',
                    label: 'CTA Icon',
                    options: ['FaCalendarPlus', 'FaExternalLinkAlt'],
                  },
                  default_banner: { type: 'file', label: 'Default Banner' },
                  custom_css: {
                    type: 'textarea',
                    lang: 'css',
                    label: 'Custom CSS',
                  },

                  container_id: { type: 'text', label: 'Container ID' },
                  container_class: { type: 'text', label: 'Container Class' },
                }}
                submitLabel="Update"
              />
            </div>
          )}

          {tabsToNames[activeTab] === 'integration' && (
            <div className="co-embed">
              <Button
                raised
                text="Copy Embed"
                icon="file_copy"
                style={{ right: 0, position: 'absolute' }}
                onClick={() => {
                  const element = document.getElementById('embed_code');
                  element.select();
                  document.execCommand('copy');
                }}
              />

              <textarea
                className="co-embed__code"
                id="embed_code"
                defaultValue={getEmbedCode(
                  process.env.EMBED_URL,
                  props.organization.item._id
                )}
              />
            </div>
          )}

          {state.delete && (
            <Dialog
              open={state.delete}
              preventOutsideDismiss
              onClose={async (evt) => {
                if (evt.detail.action === 'delete') {
                  await props.deleteDocumentItem(props.match.params);
                }

                if (evt.detail.action === 'cancel') {
                  this.setState({ delete: false });
                }
              }}
            >
              <DialogTitle>Delete Item</DialogTitle>
              <DialogContent style={{ width: '400px' }}>
                {props.document.item[`name__${props.ui.language}`]}
              </DialogContent>
              <DialogActions>
                <DialogButton action="cancel">No, abort!</DialogButton>
                <DialogButton action="delete" raised>
                  Yes, delete
                </DialogButton>
              </DialogActions>
            </Dialog>
          )}
        </div>
      </CoreLayout>
    );
  }
}

const mapDispatchToProps = {
  fetchOrganizationItem,
  organizationFormChange,
  organizationFormSubmit,
  fetchDocumentList,
  fetchDocumentItem,
  deleteDocumentItem,
  documentFormChange,
  documentFormSubmit,
  filterList,
  goToPage,
  sortList,
};

const mapStateToProps = (state) => ({
  router: state.router,
  ui: state.ui,
  document: state.document,
  organization: state.organization,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationItem);
