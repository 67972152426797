import { interfaceMessage, globalMessage } from 'react-material-site/lib/modules/ui'
import { Xhr } from 'react-material-site/lib/utils/xhr'
import { getPagerHeaders } from 'react-material-site/lib/utils/pager'
import { goToPage } from 'react-material-site/lib/modules/router'
import { flattenData, nestData } from '../utils/content'
import { PostFiles } from '../utils/xhr'

// ------------------------------------
// Constants
// ------------------------------------
export const DOCUMENT_UPDATE = 'DOCUMENT_UPDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const documentUpdate = (params) => {
  return {
    type    : DOCUMENT_UPDATE,
    payload : params
  }
}

export const fetchDocumentList = (params) => {
  return async (dispatch, getState) => {
    const search = getState().router.location.search
    
    try {
      const response = await Xhr(`${process.env.API_URL}/organizations/${params._id}/items${search}`)
      dispatch(documentUpdate({ pager: getPagerHeaders(response.xhr), list: response.body }))
    } catch (error) {
      console.error(error)
      dispatch(interfaceMessage('API_ERROR'))
    }
  }
}

export const fetchDocumentItem = (params) => {
  return async (dispatch) => {
    if (!params._id) {
      console.error('Unable to fetch without _id')
      return
    }

    if (!params._item_id) {
      console.error('Unable to fetch without _item_id')
      return
    }

    try {
      const response = await Xhr(`${process.env.API_URL}/organizations/${params._id}/items/${params._item_id}`)

      const item = flattenData(response.body)
      dispatch(documentUpdate({ item }))
    } catch (error) {
      console.error(error)
      dispatch(interfaceMessage('API_ERROR'))
    }
  }
}

export const deleteDocumentItem = (params) => {
  return async (dispatch) => {
    if (!params._id) {
      console.error('Unable to delete without _id')
      return
    }

    if (!params._item_id) {
      console.error('Unable to delete without _item_id')
      return
    }

    try {
      await Xhr(`${process.env.API_URL}/organizations/${params._id}/items/${params._item_id}`, {}, { method: 'DELETE' })
      await dispatch(fetchDocumentList({ _id: params._id }))
      await dispatch(goToPage(`/organizations/${params._id}/items`))
      await dispatch(globalMessage('Item Deleted'))
    } catch (error) {
      console.error(error)
      dispatch(interfaceMessage('API_ERROR'))
    }
  }
}


export const documentFormChange = (event, name) => {
  return async (dispatch, getState) => {
    const document = getState().document
    const form = document.form
    const files = document.files

    // TODO: Implement a more permanent solution
    // currently short-circuiting to allow html/markdown editor
    if (name && name.startsWith('description')) {
      form[name] = event
    } else {
      if (event.target.type === 'file' && event.target.files) {
        Array.from(event.target.files).forEach((file) => {
          files[event.target.name] = file
        })
      }
  
      if (event.target.type === 'checkbox') {
        form[event.target.name] = event.target.checked
      } else {
        form[event.target.name] = event.target.value
      }
    }
    
    dispatch(documentUpdate({ form, files }))
  }
}

export const documentFormSubmit = (event) => {
  return async (dispatch, getState) => {
    event.preventDefault()
    const _id = getState().organization.item._id
    if (!_id) {
      console.error('Could not submit document form with no organization')
      return
    }
    
    const _item_id = event.target.getAttribute('data-form-id')
    const document = getState().document
    const files = document.files
    
    try {
      if (_item_id) {
        const form = nestData(Object.assign({}, document.item, document.form))
        await Xhr(`${process.env.API_URL}/organizations/${_id}/items/${_item_id}`, form, { method: 'PUT' })
        if (Object.keys(files).length > 0) {          
         await PostFiles(`${process.env.API_URL}/organizations/${_id}/items/${_item_id}/upload`, files)
        }

        await dispatch(documentUpdate({ form: {}, files: [] }))
        await dispatch(fetchDocumentItem({ _id, _item_id }))
        await dispatch(globalMessage('Item Updated'))
      } else {
        //import { sortList, filterList, goToPage } from 'react-material-site/lib/modules/router'
        //console.log(files)
        const form = nestData(document.form)
        const response = await Xhr(`${process.env.API_URL}/organizations/${_id}/items`, form, { method: 'POST' })
        const item = response.body

        if (Object.keys(files).length > 0) {          
          await PostFiles(`${process.env.API_URL}/organizations/${_id}/items/${item._id}/upload`, files)
        }
        
        await dispatch(goToPage(`/organizations/${_id}/items/${item._id}`))
        await dispatch(documentUpdate({ form: {}, files: [] }))
        await dispatch(fetchDocumentItem({ _id, _item_id: item._id }))
        await dispatch(globalMessage('Item Created'))
      }

      return true
    } catch (err) {
      console.log(err)
      console.error(err.response.body.message)
      dispatch(globalMessage(err.response.body.message))
      return false
    }
  }
}

export const actions = {
  fetchDocumentList,
  fetchDocumentItem,
  deleteDocumentItem,
  documentFormChange,
  documentFormSubmit
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [DOCUMENT_UPDATE] : (state, action) => Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  pager: {},
  list: [],
  item: {},
  //form: {},
  form: {
    /*
    // Primary Fields
    name__en: 'English',
    name__fr: 'French',
    year: 2009,
    date: '2002-07-23',
    type: 'journal article',
    author: 'Benjin Stark',
    theme: 'donation matching',
    domain: 'behavioural science',

    // Description Fields
    short_description__en: 'Short Desc EN',
    short_description__fr: 'Short Desc FR',
    description__en: 'Description Description Description Description Description Description EN',
    description__fr: 'Description Description Description Description Description Description FR',

    // Image fields
    //thumbnail_url: '',
    //image_url: '',
    //banner_url: '',

    // Asset Fields
    video_url: 'https://youtube.com/watch?v=4r7wHMg5Yjg',
    document_url: 'http://pdf995.com/samples/pdf.pdf',

    // Partner Fields
    partner_name: 'Partner Name',
    partner_url: 'http://www.poussesurbaines.org/',
    partner_logo: 'http://vitrinedenosactions.fgmtl.org/static/img/grants/logos/poussesurbaines_logo.jpg',

    // Other Fields
    linking_data: `{"@context":"http://schema.org", "@type": "Webpage"}`,
    meta_data: `{"orcid":12131231, "doi": "https://ajah.ca"}`
    */
  },
  files: {}
}

export default function documentReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
