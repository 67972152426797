import React from 'react';
import { FilePond } from 'react-filepond'; // https://pqina.nl/filepond/docs/
import { DialogButton } from 'react-material-site/lib/components/Dialog';

import { countItems } from '../../utils/import-export';

import 'filepond/dist/filepond.min.css';
import './ImportExport.scss';

const ImportExport = ({ id }) => {
  const [files, setFiles] = React.useState([]);
  const [importResult, setImportResult] = React.useState([]);

  const handleExport = async () => {
    fetch(`${process.env.API_URL}/organizations/${id}/items/export`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/csv',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${id}.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <>
      <div className="import-export-container">
        <DialogButton onClick={handleExport}>Export all Items</DialogButton>
        <div className="import--desc">
          <p>
            At the moment, the import feature is still in beta. In order to
            minimize errors, it is better to export your data first and use a
            copy of that csv and import an updated copy of that file below.
          </p>
          <p>
            We recommend removing all items that you do not want to edit from
            the file. Keeping only the new and/or updated items. The server will
            overwrite all items in the file, even if they are identical. The
            server WILL NOT delete any items.
          </p>
          <p>
            Do not remove the id of an existing item from the file. The server
            uses that to know whether to update an item or add a new one. New
            items won't have a value in there.{' '}
            <em>DO NOT manually add a value to the id column.</em>
          </p>
        </div>
        <FilePond
          files={files}
          onupdatefiles={setFiles}
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          credits={false}
          server={{
            url: `${process.env.API_URL}/organizations/${id}/items/import`,
            process: {
              onload: (res) => {
                setImportResult(JSON.parse(res).result);
              },
            },
          }}
        />
      </div>
      {Boolean(importResult.length) && (
        <div className="import--results">
          <h4>Import status</h4>
          <ul>
            <li>Added: {countItems(importResult, 'added')}</li>
            <li>Updated: {countItems(importResult, 'updated')}</li>
            <li>Errors: {countItems(importResult, 'error')}</li>
          </ul>
        </div>
      )}
    </>
  );
};

export default ImportExport;
