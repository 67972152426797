import React from 'react';

import Form from '../../components/Form';
import { getLanguageFields } from '../../utils/form';

export const Item = (props) => (
  <Form
    id={props.id}
    style={{ background: 'none' }}
    onChange={props.onChange}
    onSubmit={props.onSubmit}
    data={props.data}
    submitLabel={props.actionLabel}
    submitDual
    fields={{
      fields__primary: { type: 'spacer', label: 'Primary Fields' },
      published: { type: 'switch', label: 'Published' },
      ...getLanguageFields(
        'name',
        { type: 'text', label: 'Name', required: true },
        props.languages
      ),
      year: {
        type: 'text',
        label: 'Year',
        error: 'Year must be a 4 digit number',
      },
      date: {
        type: 'date',
        label: 'Date',
      },
      type: {
        type: 'text',
        label: 'Type',
        required: true,
        pattern: '[a-z ]{0,}',
      },
      author: { type: 'text', label: 'Author' },
      theme: { type: 'text', label: 'Theme' },
      domain: { type: 'text', label: 'Domain' },

      description__fields: { type: 'spacer', label: 'Description Fields' },
      ...getLanguageFields(
        'short_description',
        { type: 'textarea', label: 'Short Description' },
        props.languages
      ),
      ...getLanguageFields(
        'description',
        { type: 'textarea', lang: 'markdown', label: 'Description' },
        props.languages
      ),

      fields__images: { type: 'spacer', label: 'Image Fields' },
      thumbnail_url: {
        type: 'file',
        label: 'Thumbnail URL',
        helper: 'The image that will be displayed in the list page',
      },
      image_url: {
        type: 'file',
        label: 'Image URL',
        helper:
          'A larger version of the image above that will be show in the details page',
      },
      banner_url: {
        type: 'file',
        label: 'Banner URL',
        helper:
          'A banner image that will be displayed as a background in the details page, defaults to the banner on the organization',
      },

      fields__assets: { type: 'spacer', label: 'Asset Fields' },
      video_url: { type: 'text', label: 'Video URL' },
      document_url: { type: 'text', label: 'Document URL' },

      fields__partners: { type: 'spacer', label: 'Partner Fields' },
      partner_name: { type: 'text', label: 'Partner Name' },
      partner_url: { type: 'text', label: 'Partner URL' },
      partner_logo: { type: 'text', label: 'Partner Logo' },

      fields__other: { type: 'spacer', label: 'Other Fields' },
      linking_data: { type: 'textarea', label: 'Linking Data' },
      meta_data: { type: 'textarea', label: 'Meta Data' },

      fields__coordinates: { type: 'spacer', label: 'Coordinates' },
      longitude: { type: 'text', label: 'Longitude' },
      latitude: { type: 'text', label: 'Latitude' },
    }}
  />
);

export default Item;
