import { interfaceMessage, globalMessage } from 'react-material-site/lib/modules/ui'
import { Xhr } from 'react-material-site/lib/utils/xhr'
import { getPagerHeaders } from 'react-material-site/lib/utils/pager'
import { PostFiles } from '../utils/xhr'

// ------------------------------------
// Constants
// ------------------------------------
export const ORGANIZATION_UPDATE = 'ORGANIZATION_UPDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const organizationUpdate = (params) => {
  return {
    type    : ORGANIZATION_UPDATE,
    payload : params
  }
}

export const fetchOrganizationList = () => {
  return async (dispatch, getState) => {
    const search = getState().router.location.search

    try {
      const response = await Xhr(`${process.env.API_URL}/organizations${search}`)
      dispatch(organizationUpdate({ pager: getPagerHeaders(response.xhr), list: response.body }))
    } catch (error) {
      console.error(error)
      dispatch(interfaceMessage('API_ERROR'))
    }
  }
}

export const fetchOrganizationItem = (params) => {
  return async (dispatch) => {
    if (!params._id) {
      console.error('Unable to fetch without _id')
      return
    }

    try {
      const response = await Xhr(`${process.env.API_URL}/organizations/${params._id}`)
      dispatch(organizationUpdate({ item: response.body }))
    } catch (error) {
      console.error(error)
      dispatch(interfaceMessage('API_ERROR'))
    }
  }
}

export const organizationFormChange = (event, name) => {
  // TODO: investigate why 'select' isn't passing 'name' in event.target
  // ...needed to pass the name in the on change func. Ugh.
  return async (dispatch, getState) => {
    const organization = getState().organization
    const form = organization.form
    const files = organization.files

    // TODO: Implement a more permanent solution
    // currently short-circuiting to allow css editor
    if (name && name === 'custom_css') {
      form[name] = event
    } else {
      if (event.target.type === 'file' && event.target.files) {
        Array.from(event.target.files).forEach((file) => {
          files[event.target.name] = file
        })
      }
  
      if (event.target && event.target.type === 'checkbox') {
        form[event.target.name] = event.target.checked
      } else {
        form[event.target.name || name] = event.target.value
      }
    }
    dispatch(organizationUpdate({ form }))
  }
}

export const organizationFormSubmit = (event) => {
  return async (dispatch, getState) => {
    event.preventDefault()

    const organization = getState().organization
    const files = organization.files
    const form = organization.form
    const _id = event.target.getAttribute('data-form-id')

    try {
      if (_id) {
        await Xhr(`${process.env.API_URL}/organizations/${_id}`, form, { method: 'PUT' })

        if (Object.keys(files).length > 0) {
          await PostFiles(`${process.env.API_URL}/organizations/${_id}/upload`, files)
        }

        await dispatch(fetchOrganizationItem({ _id }))
      } else {
        await Xhr(`${process.env.API_URL}/organizations`, form, { method: 'POST' })
        await dispatch(fetchOrganizationList())
      }

      await dispatch(organizationUpdate({ form: {}, files: [] }))
      await dispatch(globalMessage('Organization Updated'))
      return true
    } catch (err) {
      console.error(err.response.body.message)
      dispatch(globalMessage(err.response.body.message))
      return false
    }
  }
}

export const actions = {
  fetchOrganizationList,
  fetchOrganizationItem,
  organizationFormChange,
  organizationFormSubmit
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ORGANIZATION_UPDATE] : (state, action) => Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  pager: {},
  list: [],
  item: {},
  form: {},
  files: {}
}

export default function organizationReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
