export const flattenData = (data, delimeter='__') => {
  const parsed = {}
  Object.keys(data).forEach((item) => {
    if (data[item].constructor.name === 'Object') {
      Object.keys(data[item]).forEach((nestedItem) => {
        parsed[`${item}${delimeter}${nestedItem}`] = data[item][nestedItem]
      })
    } else {
      parsed[item] = data[item]
    }
  })

  return parsed
}

export const nestData = (data, delimeter='__') => {
  const parsed = {}
  Object.keys(data).forEach((item) => {
    if (item.includes(delimeter)) {
      const [parent, name] = item.split(delimeter)
      if (!parent) {
        return
      }

      if (!parsed[parent]) {
        parsed[parent] = {}
      }

      parsed[parent][name] = data[item]
    } else {
      parsed[item] = data[item]
    }
  })

  return parsed
}