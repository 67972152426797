import React, { Component } from 'react'
import { connect } from 'react-redux'
//import moment from 'moment'

import CoreLayout from 'react-material-site/lib/layouts/CoreLayout'
import { sortList, filterList, goToPage } from 'react-material-site/lib/modules/router'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton
} from 'react-material-site/lib/components/Dialog'
import Fab from 'react-material-site/lib/components/Fab'
import Table from 'react-material-site/lib/components/Table'
import Pager from 'react-material-site/lib/components/Pager'
import Form from 'react-material-site/lib/components/Form'

import { fetchOrganizationList, organizationFormChange, organizationFormSubmit } from '../../modules/organization'

import './OrganizationList.scss'

class OrganizationList extends Component {
  constructor (props) {
    super(props)
    this.state = {newOpen: false}
  }

  componentWillMount() {
    this.props.fetchOrganizationList()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.router.location.search !== nextProps.router.location.search) {
      this.props.fetchOrganizationList()
    }
  }

  render() {
    const props = this.props
    const search = new URLSearchParams(props.router.location.search)

    return (
      <CoreLayout {...props}>
        <div style={{textAlign: 'center', margin: '20px 10px', minHeight: '900px', position: 'relative'}}>
          <Fab
            icon='add'
            label='New'
            onClick={evt => this.setState({newOpen: true})}
            style={{left: '-150px', top: '35px', zIndex: '2'}}
          />

          <Pager
            {...props.organization.pager}
            parseUrl={(url) => url ? url.replace(process.env.API_URL, '') : ''}
            showCounts={false}
          />

          <Table
            wrap
            fields={{
              name: {label: 'Name', style: {width: '100px'}, filter: true},
              domain: {label: 'Domain', style: {width: '100px'}, filter: true},
              // TODO make filter work for boolean
              //published: {label: 'Published', style: {width: '100px'}, filter: true, render: (value) => (new String(value)) },
              published: {label: 'Published', style: {width: '100px'}, render: (value) => value.toString() },
            }}

            filterChange={props.filterList}
            search={search}

            rowClick={(row) => {
              props.goToPage(`${props.router.location.pathname}/${row['_id']}/items`)
            }}

            sortChange={props.sortList}
            sortField={search.get('sortField')}
            sortDir={search.get('sortDir')}

            data={props.organization.list}
          />

          <Pager
            {...props.organization.pager}
            parseUrl={(url) => url ? url.replace(process.env.API_URL, '') : ''}
            showCounts={false}
          />
        </div>

        <Dialog
          open={this.state.newOpen}
          preventOutsideDismiss
          onClose={evt => {
            this.setState({newOpen: false})
          }}
        >
          <DialogTitle>New Organization</DialogTitle>
          <DialogContent style={{width: '400px'}}>
            <Form
              style={{ background: 'none' }}
              onChange={props.organizationFormChange}
              onSubmit={async (event) => {
                const state = await props.organizationFormSubmit(event)
                if (state) {
                  this.setState({ newOpen: false })
                }
              }}
              data={props.organization.form}
              fields={{
                name: { type: 'text', label: 'Name', required: true }
              }}
              submitLabel='Create'
            />
          </DialogContent>
          <DialogActions>
            <DialogButton action='close'>Cancel</DialogButton>
          </DialogActions>
        </Dialog>
      </CoreLayout>
    )
  }
}

const mapDispatchToProps = {
  fetchOrganizationList,
  organizationFormChange,
  organizationFormSubmit,
  filterList,
  goToPage,
  sortList
}

const mapStateToProps = state => ({
  router: state.router,
  ui: state.ui,
  content: state.content,
  organization: state.organization
})

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationList)
