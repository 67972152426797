import superagent from 'superagent'

// Post files
export const PostFiles = (url, files) => {
  if (!url) {
    throw new TypeError('You must provide a url to PostFiles to')
  }
  
  const promise = superagent.post(url)
  Object.keys(files).forEach(name => {
    promise.attach(name, files[name])
  })

  const auth = localStorage.getItem('access_token')
  if (auth) {
    promise.set('Authorization', 'Bearer ' + auth)
  }

  return promise
}
